import { Component, OnInit, TemplateRef } from '@angular/core';
import { SubscribableComponent } from 'ngx-subscribable';

import { PopupPortalInternalService } from './popup-portal.internal-service';
import { PortalPopupAgent } from './popup-portal.service';

@Component({
    selector: 'core-popup-portal',
    templateUrl: './popup-portal.component.html',
    styleUrls: ['./popup-portal.component.less'],
})
export class PopupPortalComponent
    extends SubscribableComponent
    implements OnInit
{
    templates = new Set<TemplateRef<{}>>();

    private agents = new Map<TemplateRef<{}>, PortalPopupAgent>();

    constructor(
        private popupPortalInternalService: PopupPortalInternalService,
    ) {
        super();
    }

    ngOnInit(): void {
        const {
            templates,
            agents,
            popupPortalInternalService: { append, remove },
        } = this;

        this.subscriptions = [
            append.subscribe(({ template, agent }) => {
                templates.add(template);

                if (agent) agents.set(template, agent);
            }),

            remove.subscribe((template) => this.hide(template, true)),
        ];
    }

    hide(template: TemplateRef<{}>, isHideByEmit = false): void {
        const childTemplates = this.getChildTemplates(template);

        childTemplates.forEach((childTemplate) => {
            const agent = this.agents.get(childTemplate);

            if (agent?.tryHide && !isHideByEmit) {
                agent?.tryHide.emit();
            } else {
                const globalTooltip =
                    document.querySelector('body > sl-tooltip');
                const attrs = globalTooltip?.attributes;

                const openCondition = attrs?.getNamedItem('open');

                if (openCondition) attrs?.removeNamedItem('open');

                this.templates.delete(childTemplate);
                this.agents.delete(childTemplate);

                agent?.hide?.emit();
            }
        });
    }

    onKey(template: TemplateRef<{}>, key: string): void {
        this.agents.get(template)?.key?.emit(key);
    }

    onIndex(template: TemplateRef<{}>, index: number): void {
        this.agents.get(template)?.index?.emit(index);
    }

    visibleOverlay(template: TemplateRef<{}>): boolean {
        return this.agents.get(template)?.visibleOverlay ?? true;
    }

    overlayColor(template: TemplateRef<{}>): string | undefined {
        return this.agents.get(template)?.overlayColor;
    }

    popupName(template: TemplateRef<{}>): string | undefined {
        return this.agents.get(template)?.popupName;
    }

    private getChildTemplates(
        parentTemplate: TemplateRef<{}>,
    ): Set<TemplateRef<{}>> {
        const parentTemplateIndex = Array.from(this.templates).indexOf(
            parentTemplate,
        );

        if (parentTemplateIndex === -1) {
            return new Set<TemplateRef<{}>>();
        }

        // отделяем дочерние попапы и возвращаем в обратном порядке от последнего открытого попапа к первому
        const arrayOfChildTemplates = Array.from(this.templates)
            .slice(parentTemplateIndex, this.templates.size)
            .reverse();

        return new Set<TemplateRef<{}>>(arrayOfChildTemplates);
    }
}
